import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'

import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/404'

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/system/login',
    Component: loadable(() => import('pages/system/login')),
    exact: true,
  },
  {
    path: '/system/forgot-password',
    Component: loadable(() => import('pages/system/forgot-password')),
    exact: true,
  },
  {
    path: '/system/register',
    Component: loadable(() => import('pages/system/register')),
    exact: true,
  },
  {
    path: '/system/lockscreen',
    Component: loadable(() => import('pages/system/lockscreen')),
    exact: true,
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
  },
  {
    path: '/system/500',
    Component: loadable(() => import('pages/system/500')),
    exact: true,
  },
  /* ==================>Manage Menu<====================== */
  {
    path: '/noton/managemenu/menuDetailsInGrid',
    Component: loadable(() => import('pages/noton/managemenu/menuDetailsInGrid')),
    exact: true,
  },

  /* ==================>Manage Client<====================== */
  {
    path: '/noton/manageclient/clientDetailsInGrid',
    Component: loadable(() => import('pages/noton/manageclient/clientDetailsInGrid')),
    exact: true,
  },
  {
    path: '/noton/manageclient/createNewClient',
    Component: loadable(() => import('pages/noton/manageclient/createNewClient')),
    exact: true,
  },
  {
    path: '/noton/manageclientlogs',
    Component: loadable(() => import('pages/noton/manageclient/manageclientlogs')),
    exact: true,
  },

  /* ==================>Home Menu<====================== */

  {
    path: '/noton/homemenu',
    Component: loadable(() => import('pages/noton/homemenu')),
    exact: true,
  },

  {
    path: '/noton/windowslist',
    Component: loadable(() => import('pages/noton/managewindows/windowslist')),
    exact: true,
  },
  {
    path: '/noton/windowdetails',
    Component: loadable(() => import('pages/noton/managewindows/windowdetails')),
    exact: true,
  },
  {
    path: '/noton/myapprovals',
    Component: loadable(() => import('pages/noton/myapprovals')),
    exact: true,
  },
  {
    path: '/noton/mychanges',
    Component: loadable(() => import('pages/noton/mychanges')),
    exact: true,
  },
  {
    path: '/noton/mychanges/changesdetails',
    Component: loadable(() => import('pages/noton/mychanges/changesdetails')),
    exact: true,
  },
  {
    path: '/noton/managechangeset',
    Component: loadable(() => import('pages/noton/managechangeset')),
    exact: true,
  },
  {
    path: '/noton/managemongosession',
    Component: loadable(() => import('pages/noton/managemongosession')),
    exact: true,
  },
  {
    path: '/noton/managechangesetworkflow',
    Component: loadable(() => import('pages/noton/managechangesetworkflow')),
    exact: true,
  },
  {
    path: '/noton/managechangesetworkflow/changesetdetails',
    Component: loadable(() => import('pages/noton/managechangesetworkflow/changesetdetails')),
    exact: true,
  },

  {
    path: '/noton/managedashboard/dashboardlist',
    Component: loadable(() => import('pages/noton/managedashboard/dashboardlist')),
    exact: true,
  },
  {
    path: '/noton/managedashboardfilters',
    Component: loadable(() => import('pages/noton/managedashboard/managedashboardfilters')),
    exact: true,
  },

  {
    path: '/noton/managebatchregister/batchRegisterList',
    Component: loadable(() => import('pages/noton/managebatchregister/batchRegisterList')),
    exact: true,
  },

  /* ===============>Application<=================== */
  {
    path: '/noton/manageapplication/manageapplicationlist',
    Component: loadable(() => import('pages/noton/manageapplication/manageapplicationlist')),
    exact: true,
  },
  {
    path: '/noton/applicationdependency',
    Component: loadable(() => import('pages/noton/manageapplication/applicationdependency')),
    exact: true,
  },
  {
    path: '/noton/managealerts/alertslist',
    Component: loadable(() => import('pages/noton/managealerts/alertslist')),
    exact: true,
  },

  {
    path: '/noton/managenotonscreens',
    Component: loadable(() => import('pages/noton/managenotonscreens')),
    exact: true,
  },

  {
    path: '/noton/managenotonmenu',
    Component: loadable(() => import('pages/noton/managenotonmenu')),
    exact: true,
  },
  {
    path: '/noton/managesection',
    Component: loadable(() => import('pages/noton/managesection')),
    exact: true,
  },

  {
    path: '/noton/settings',
    Component: loadable(() => import('pages/noton/settings')),
    exact: true,
  },
  {
    path: '/noton/managetables',
    Component: loadable(() => import('pages/noton/managetables')),
    exact: true,
  },
  {
    path: '/noton/createnewtable',
    Component: loadable(() => import('pages/noton/managetables/createnewtable')),
    exact: true,
  },
  {
    path: '/noton/tabledetails',
    Component: loadable(() => import('pages/noton/managetables/tabledetails')),
    exact: true,
  },
  {
    path: '/noton/managefieldgroup',
    Component: loadable(() => import('pages/noton/managefieldgroup/fieldgrouplist')),
    exact: true,
  },
  {
    path: '/noton/managemessages',
    Component: loadable(() => import('pages/noton/managemessages/messageslist')),
    exact: true,
  },
  {
    path: '/noton/managebasereference',
    Component: loadable(() => import('pages/noton/managebasereference/basereferencelist')),
    exact: true,
  },
  {
    path: '/noton/createnewbasereference',
    Component: loadable(() => import('pages/noton/managebasereference/createnewbasereference')),
    exact: true,
  },
  {
    path: '/noton/managereference',
    Component: loadable(() => import('pages/noton/managereference/referencelist')),
    exact: true,
  },
  {
    path: '/noton/createreference',
    Component: loadable(() => import('pages/noton/managereference/createreference')),
    exact: true,
  },
  {
    path: '/noton/referencelistdetails',
    Component: loadable(() => import('pages/noton/managereference/referencelistdetails')),
    exact: true,
  },
  {
    path: '/noton/managedefaultcolumns',
    Component: loadable(() => import('pages/noton/managedefaultcolumns/defaultcolumnlist')),
    exact: true,
  },
  {
    path: '/noton/sqlquerytool',
    Component: loadable(() => import('pages/noton/sqlquerytool')),
    exact: true,
  },
  {
    path: '/noton/registerreporttemplate',
    Component: loadable(() => import('pages/noton/registerreporttemplate')),
    exact: true,
  },
  {
    path: '/noton/managemodule',
    Component: loadable(() => import('pages/noton/managemodule/modulelist')),
    exact: true,
  },
  {
    path: '/noton/managemodule/moduleversiondetails',
    Component: loadable(() => import('pages/noton/managemodule/moduleversiondetails')),
    exact: true,
  },
  {
    path: '/noton/managereport',
    Component: loadable(() => import('pages/noton/managereport/managereportlist')),
    exact: true,
  },
  {
    path: '/noton/managefilter',
    Component: loadable(() => import('pages/noton/managereport/managefilter')),
    exact: true,
  },
  {
    path: '/noton/manageautocomplete',
    Component: loadable(() => import('pages/noton/manageautocomplete/autocompletelist')),
    exact: true,
  },

  /*   {
    path: '/noton/manageprocess',
    Component: loadable(() => import('pages/noton/manageprocess')),
    exact: true,
  }, */

  /* ==================>Process<====================== */

  {
    path: '/noton/manageprocess/processDetailsInGrid',
    Component: loadable(() => import('pages/noton/manageprocess/processDetailsInGrid')),
    exact: true,
  },
  {
    path: '/noton/manageprocess/parameters',
    Component: loadable(() => import('pages/noton/manageprocess/parameters')),
    exact: true,
  },
  {
    path: '/noton/manageprocess/queryDetails',
    Component: loadable(() => import('pages/noton/manageprocess/queryDetails')),
    exact: true,
  },
  /* ==================>Stored Procedure<====================== */
  {
    path: '/noton/storedprocedure/storedproceduresdetailsingrid',
    Component: loadable(() => import('pages/noton/storedprocedure/storedproceduresdetailsingrid')),
    exact: true,
  },
  {
    path: '/noton/storedprocedure/createnewstoredprocedure',
    Component: loadable(() => import('pages/noton/storedprocedure/createnewstoredprocedure')),
    exact: true,
  },

  /* ==================>Project Management<====================== */
  {
    path: '/noton/projectmgmt/documents',
    Component: loadable(() => import('pages/noton/projectmgmt/documents')),
    exact: true,
  },
  {
    path: '/noton/projectmgmt/projectplan',
    Component: loadable(() => import('pages/noton/projectmgmt/projectplan')),
    exact: true,
  },
  {
    path: '/noton/projectmgmt/specifications',
    Component: loadable(() => import('pages/noton/projectmgmt/specifications')),
    exact: true,
  },
  {
    path: '/noton/projectmgmt/tasks',
    Component: loadable(() => import('pages/noton/projectmgmt/tasks')),
    exact: true,
  },
  {
    path: '/noton/projectmgmt/features',
    Component: loadable(() => import('pages/noton/projectmgmt/features')),
    exact: true,
  },

  /* ==================>360 Developer<====================== */

  {
    path: '/noton/360developer/360MenuDetailsInGrid',
    Component: loadable(() => import('pages/noton/360developer/360MenuDetailsInGrid')),
    exact: true,
  },
  {
    path: '/noton/360developer/createNew360Menu',
    Component: loadable(() => import('pages/noton/360developer/createNew360Menu')),
    exact: true,
  },
  {
    path: '/noton/360developer/submenudetails',
    Component: loadable(() => import('pages/noton/360developer/submenudetails')),
    exact: true,
  },
  {
    path: '/noton/360developer/360AdminMenuDetails',
    Component: loadable(() => import('pages/noton/360developer/360AdminMenuDetails')),
    exact: true,
  },

  /* ==================>DevOps<====================== */
  /* {
    path: '/pgBadger',
    Component: loadable(() => <a href="https://tserv01.exceloid.com/pgbadger-preprod.html" target="_blank" rel="noopener noreferrer" />),
    exact: true,
  }, */
  {
    path: '/noton/devops/serverstatus',
    Component: loadable(() => import('pages/noton/devops/serverstatus')),
    exact: true,
  },
  {
    path: '/noton/devops/codequality',
    Component: loadable(() => import('pages/noton/devops/codequality')),
    exact: true,
  },
  {
    path: '/noton/devops/pipelines',
    Component: loadable(() => import('pages/noton/devops/pipelines')),
    exact: true,
  },
  {
    path: '/noton/devops/deploymentjobs',
    Component: loadable(() => import('pages/noton/devops/deploymentjobs')),
    exact: true,
  },

  /* ==================>Gitlab<====================== */
  {
    path: '/noton/gitlab/repository',
    Component: loadable(() => import('pages/noton/gitlab/repository')),
    exact: true,
  },
  {
    path: '/noton/gitlab/issues',
    Component: loadable(() => import('pages/noton/gitlab/issues')),
    exact: true,
  },
  {
    path: '/noton/gitlab/snippets',
    Component: loadable(() => import('pages/noton/gitlab/snippets')),
    exact: true,
  },

  /* ==================>Licensing<====================== */
  {
    path: '/noton/licensing/licencemanager',
    Component: loadable(() => import('pages/noton/licensing/licencemanager')),
    exact: true,
  },

  /* ==================>Logs<====================== */
  {
    path: '/noton/logs/coregraphql',
    Component: loadable(() => import('pages/noton/logs/coregraphql')),
    exact: true,
  },
  {
    path: '/noton/logs/360graphql',
    Component: loadable(() => import('pages/noton/logs/360graphql')),
    exact: true,
  },
  {
    path: '/noton/logs/supplychaingraphql',
    Component: loadable(() => import('pages/noton/logs/supplychaingraphql')),
    exact: true,
  },
  {
    path: '/noton/logs/desktoppos',
    Component: loadable(() => import('pages/noton/logs/desktoppos')),
    exact: true,
  },
  {
    path: '/noton/logs/mobilepos',
    Component: loadable(() => import('pages/noton/logs/mobilepos')),
    exact: true,
  },
  {
    path: '/noton/logs/mobilewarehouse',
    Component: loadable(() => import('pages/noton/logs/mobilewarehouse')),
    exact: true,
  },
  {
    path: '/noton/logs/erpfrontend',
    Component: loadable(() => import('pages/noton/logs/erpfrontend')),
    exact: true,
  },
  {
    path: '/noton/logs/360frontend',
    Component: loadable(() => import('pages/noton/logs/360frontend')),
    exact: true,
  },

  /* ==================>Testing<====================== */
  {
    path: '/noton/testing/graphqltestcase',
    Component: loadable(() => import('pages/noton/testing/graphqltestcase')),
    exact: true,
  },
  {
    path: '/noton/testing/reactjstestcase',
    Component: loadable(() => import('pages/noton/testing/reactjstestcase')),
    exact: true,
  },

  /* ==================>Performance<====================== */
  {
    path: '/noton/performance/erpserver',
    Component: loadable(() => import('pages/noton/performance/erpserver')),
    exact: true,
  },
  {
    path: '/noton/performance/authserver',
    Component: loadable(() => import('pages/noton/performance/authserver')),
    exact: true,
  },
  {
    path: '/noton/performance/360server',
    Component: loadable(() => import('pages/noton/performance/360server')),
    exact: true,
  },
  {
    path: '/noton/preference',
    Component: loadable(() => import('pages/noton/preference')),
    exact: true,
  },
  /* ==================>Admin<====================== */
  {
    path: '/noton/admin/roles',
    Component: loadable(() => import('pages/noton/admin/roles')),
    exact: true,
  },
  {
    path: '/noton/admin/roles/roleaccess',
    Component: loadable(() => import('pages/noton/admin/roles/roleaccess')),
    exact: true,
  },
  {
    path: '/noton/admin/users',
    Component: loadable(() => import('pages/noton/admin/users')),
    exact: true,
  },
  {
    path: '/noton/admin/accesscontrol',
    Component: loadable(() => import('pages/noton/admin/accesscontrol')),
    exact: true,
  },
  {
    path: '/noton/admin/manageGraton',
    Component: loadable(() => import('pages/noton/admin/manageGraton')),
    exact: true,
  },

  /* ====================Global Parameters============================= */
  {
    path: '/noton/manageglobalparameters',
    Component: loadable(() => import('pages/noton/manageglobalparameters')),
    exact: true,
  },
  /* ====================Process Pipeline============================= */
  {
    path: '/noton/manageprocesspipeline',
    Component: loadable(() => import('pages/noton/manageprocesspipeline')),
    exact: true,
  },
  {
    path: '/noton/manageprocesspipeline/processpipelineparameters',
    Component: loadable(() =>
      import('pages/noton/manageprocesspipeline/processpipelineparameters'),
    ),
    exact: true,
  },
  /* ==================Actions============================= */
  {
    path: '/noton/manageactions',
    Component: loadable(() => import('pages/noton/manageactions')),
    exact: true,
  },
  {
    path: '/noton/manageactions/actionproperties',
    Component: loadable(() => import('pages/noton/manageactions/actionproperties')),
    exact: true,
  },
  {
    path: '/noton/settings',
    Component: loadable(() => import('pages/noton/settings')),
    exact: true,
  },
  /* ==================Application Integration============================= */
  {
    path: '/noton/manageapplicationintegration',
    Component: loadable(() => import('pages/noton/manageapplicationintegration')),
    exact: true,
  },
  {
    path: '/noton/manageapplicationintegration/applicationintegrationproperties',
    Component: loadable(() =>
      import('pages/noton/manageapplicationintegration/applicationintegrationproperties'),
    ),
    exact: true,
  },
  {
    path: '/noton/manageapplicationintegration/applicationIntegrationApi',
    Component: loadable(() =>
      import('pages/noton/manageapplicationintegration/applicationIntegrationApi'),
    ),
    exact: true,
  },
  {
    path: '/noton/deleteDBObjects',
    Component: loadable(() => import('pages/noton/deleteDBObjects')),
    exact: true,
  },
  /* ====================Batch User============================= */
  {
    path: '/noton/managebatchuser',
    Component: loadable(() => import('pages/noton/managebatchuser')),
    exact: true,
  },
  /* ====================Process Pipeline Task============================= */
  {
    path: '/noton/manageprocesspipelinetask',
    Component: loadable(() => import('pages/noton/manageprocesspipelinetask')),
    exact: true,
  },
  {
    path: '/noton/manageprocesspipelinetask/processPiplelineTaskDetails',
    Component: loadable(() =>
      import('pages/noton/manageprocesspipelinetask/processPiplelineTaskDetails'),
    ),
    exact: true,
  },
  /* ====================Data Source============================= */
  {
    path: '/noton/managedatasource',
    Component: loadable(() => import('pages/noton/managedatasource')),
    exact: true,
  },
  /* ====================NT Google============================= */
  {
    path: '/noton/managegoogle',
    Component: loadable(() => import('pages/noton/managegoogle')),
    exact: true,
  },
  /* ====================Form============================= */
  {
    path: '/noton/manageform',
    Component: loadable(() => import('pages/noton/manageform')),
    exact: true,
  },
  /* ====================API Builder============================= */
  {
    path: '/noton/manageapibuilder',
    Component: loadable(() => import('pages/noton/manageapibuilder')),
    exact: true,
  },
  {
    path: '/noton/manageapibuilder/apibuilderdetails',
    Component: loadable(() =>
      import('pages/noton/manageapibuilder/apibuilderdetails'),
    ),
    exact: true,
  },
  /* ====================CW Instance============================= */
  {
    path: '/noton/managecwinstance',
    Component: loadable(() => import('pages/noton/managecwinstance')),
    exact: true,
  },
  {
    path: '/noton/managecwinstance/instanceDetails',
    Component: loadable(() => import('pages/noton/managecwinstance/instanceDetails')),
    exact: true,
  },
  /* ====================Manage Big Query============================= */
  {
    path: '/noton/managebigquery',
    Component: loadable(() => import('pages/noton/managebigquery')),
    exact: true,
  },
  /* ====================Manage Data Processing============================= */
  {
    path: '/noton/managedataprocessing',
    Component: loadable(() => import('pages/noton/managedataprocessing')),
    exact: true,
  },
  {
    path: '/noton/managedataprocessing/dataprocessingdetails',
    Component: loadable(() => import('pages/noton/managedataprocessing/dataprocessingdetails')),
    exact: true,
  },
  {
    path: '/noton/managedataprocessing/dataprocessinglogs',
    Component: loadable(() => import('pages/noton/managedataprocessing/dataprocessinglogs')),
    exact: true,
  },
  /* ====================Manage Release Management============================= */
  {
    path: '/noton/managereleasemanagement',
    Component: loadable(() => import('pages/noton/managereleasemanagement')),
    exact: true,
  },
  {
    path: '/noton/managereleasemanagement/releasemanagementdetails',
    Component: loadable(() => import('pages/noton/managereleasemanagement/releasemanagementdetails')),
    exact: true,
  },

  /* ====================Manage Email Template============================= */
  {
    path: '/noton/manageemailtemplate',
    Component: loadable(() => import('pages/noton/manageemailtemplate')),
    exact: true,
  },
  /* ====================Manage Email Source============================= */
  {
    path: '/noton/manageemailsource',
    Component: loadable(() => import('pages/noton/manageemailsource')),
    exact: true,
  },
  /* ====================Manage Audit Logs============================= */
  {
    path: '/noton/manageauditlogs',
    Component: loadable(() => import('pages/noton/manageauditlogs')),
    exact: true,
  },
  /* ====================Manage Network Statuss============================= */
  /* {
    path: '/noton/managenetworkstatus',
    Component: loadable(() => import('pages/noton/managenetworkstatus')),
    exact: true,
  }, */

  /* ====================SQL Query Designer(Dileep)============================= */

  {
    path: '/noton/sqlquerydesigner',
    Component: loadable(() => import('pages/noton/sqlquerydesigner')),
    exact: true,
  },

  /* ====================Tenant Setup(Nikhil)============================= */

  {
    path: '/noton/tenantSetup',
    Component: loadable(() => import('pages/noton/managetenantsetup')),
    exact: true,
  },

  /* ====================Country Setup(Nikhil)============================= */

  {
    path: '/noton/manageCountryDatasets',
    Component: loadable(() => import('pages/noton/managecountrysetup')),
    exact: true,
  },

  /* ====================manageNTInstance(Nikhil)============================= */

  {
    path: '/noton/manageNTInstance',
    Component: loadable(() => import('pages/noton/managentinstance')),
    exact: true,
  },
  


  



  
  
]

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Router extends React.Component {
  render() {
    const {
      history,
      settings: { routerAnimation },
    } = this.props
    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={(props) => {
              const {
                children,
                location: { pathname },
              } = props
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={pathname}
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          >
            <Route exact path="/" render={() => <Redirect to="/noton/homemenu" />} />
            {routes.map(({ path, Component, exact }) => (
              <Route path={path} key={path} exact={exact}>
                <Component />
              </Route>
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default Router
