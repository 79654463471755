module.exports = {
/* DEV Server URL'S 
=================================================== */
//  URL: 'https://tests.noton.dev/cw-noton/graphql',
//  Auth: 'https://identity-test.mycw.in/cw-identity/oauth/token',

/* Test Server URL'S 
=================================================== */
//  URL:'https://s20q1.mycw.in/cw-noton/graphql',
//  Auth:'https://s20q1.mycw.in/cw-identity/oauth/token'

/* OFC-Test Server URL'S 
=================================================== */

//  URL:'https://sofc-test-analytics.noton.dev/cw-noton/graphql',
//  Auth:'https://auth-ofc-test-analytics.mycw.in/cw-identity/oauth/token'
	
/* OFC-Live Server URL'S 
=================================================== */

//  URL:'https://sofc01.noton.dev/cw-noton/graphql',
//  Auth:'https://sauth.cw.solutions/cw-identity/oauth/token'


/* Pre-prod Server URL'S 
=================================================== */
 
 // URL:'https://sapp.noton.dev/cw-noton/graphql',
 // Auth:'https://s360app.mycw.in/cw-identity/oauth/token'

/* Anandyas Server URL's
============================================ */
//  URL: 'https://s2anandyas.noton.dev/cw-noton/graphql',
//  Auth: 'https://ianandyas.cw.solutions/cw-identity/oauth/token'

/* Demo Server URL's
=============================================== */

//  URL: 'https://sdemos.noton.dev/cw-noton/graphql',
//  Auth: 'https://identity-demos.cw.solutions/cw-identity/oauth/token' 

/* CWOne Server URL's
=============================================== */

//  URL: 'https://sone.noton.dev/cw-noton/graphql',
//  Auth: 'https://sone.mycw.in/cw-identity/oauth/token'

/* t01 Server URL's
=============================================== */

//  URL: 'https://t01core.noton.dev/cw-noton/graphql',
//  Auth: 'https://t01auth.cw.solutions/cw-identity/oauth/token'

/* s02 Server URL's
=============================================== */

//  URL: 'https://s02core.noton.dev/cw-noton/graphql',
//  Auth: 'https://s02auth.cw.solutions/cw-identity/oauth/token'

/* s03 Server URL's
=============================================== */

//  URL: 'https://s03core.noton.dev/cw-noton/graphql',
//  Auth: 'https://s03auth.cw.solutions/cw-identity/oauth/token'

/* s04 Server URL's
=============================================== */

//  URL: 'https://s04core.noton.dev/cw-noton/graphql',
//  Auth: 'https://s04auth.cw.solutions/cw-identity/oauth/token'

/* s05 Server URL's
=============================================== */

//  URL: 'https://s05core.noton.dev/cw-noton/graphql',
//  Auth: 'https://s05auth.cw.solutions/cw-identity/oauth/token'

/* s06 Server URL's
=============================================== */

//  URL: 'https://s06core.noton.dev/cw-noton/graphql',
//  Auth: 'https://s06auth.cw.solutions/cw-identity/oauth/token'

/* cwexceloid Server URL's
=============================================== */

//  URL: 'https://sexceloid.noton.dev/cw-noton/graphql',
//  Auth: 'https://sexceloid.mycw.in/cw-identity/oauth/token'

/* t06 Server URL's
=============================================== */

  URL: 'https://t06core.noton.dev/cw-noton/graphql',
  Auth: 'https://t06auth.cw.solutions/cw-identity/oauth/token' 

/* cw test Server URL's
=============================================== */

//  URL: 'https://stest.noton.dev/cw-noton/graphql',
//  Auth: 'https://stest-auth.mycw.in/cw-identity/oauth/token' 

/* cw dev Server URL's
=============================================== */

//  URL: 'https://sdev.noton.dev/cw-noton/graphql',
//  Auth: 'https://sdev-auth.mycw.in/cw-identity/oauth/token' 

/* demos2 Server URL's
=============================================== */

//  URL: 'https://sdemos2.noton.dev/cw-noton/graphql',
//  Auth: 'https://identity-demos2.cwsuite.com/cw-identity/oauth/token' 

}
